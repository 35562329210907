import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { LotImagesType } from "interfaces/LotImagesInterface";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { RequestStatus } from "interfaces/RequestStatus";
import { WoodPileInterface } from "interfaces/WoodPile";
import { LotOfferInterface } from "../../interfaces/LotOfferInterface";
import {
  deleteImagesFromLotOffer,
  fetchLotOffer,
  fetchLotOfferArgument,
  saveLotOffer,
  deleteLotOffer
} from "./fetchLotOffer";

export enum LotOfferStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
}


export interface lotListState {
  status: RequestStatus;
  lotOffer?: LotOfferInterface;
  changedValues: (keyof LotOfferInterface)[];
  deletedImageIds: string[];
  clickedMarker?: LotOfferLocationInterface;
}

const initialState: lotListState = {
  status: RequestStatus.idle,
  lotOffer: undefined,
  changedValues: [],
  deletedImageIds: [],
  clickedMarker: undefined
};

export const getLotOffer = createAsyncThunk("lotOffer/fetchLotOffer", async (args: fetchLotOfferArgument, { getState }) => {
  const { user } = getState() as RootState;
  // console.log(user)
  const response = await fetchLotOffer({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const patchLotOffer = createAsyncThunk("lotOffer/saveLotOffer", async (args, { getState }) => {
  const { lotOffer, user } = getState() as RootState;
  // console.log(user)
  if (!lotOffer.lotOffer) {
    return { headers: {}, data: {} };
  }
  const response = await saveLotOffer({
    changedValues: lotOffer.changedValues,
    id: lotOffer.lotOffer.id,
    lotOffer: lotOffer.lotOffer,
    endpoint: user.role,
  });
  return { headers: response.headers, data: response.data };
});

export const deleteLotOfferSlice = createAsyncThunk("lotOffer/deleteLotOffer", async (args, { getState }) => {

  const state = getState() as RootState;
  if (!state.lotOffer.lotOffer) {
    return { headers: {}, data: {} };
  }
  const response = await deleteLotOffer({
    id: state.lotOffer.lotOffer.id,
    userRole: state.user.role,
  });
  return { headers: response.headers, data: response.data };
});

export const patchImages = createAsyncThunk("lotOffer/patchImages", async (args, { getState }) => {
  const { lotOffer, user } = getState() as RootState;
  if (!lotOffer.deletedImageIds && !lotOffer.lotOffer) {
    return { headers: {}, data: {} };
  }
  lotOffer.deletedImageIds.forEach(async (imageId) => {
    const response = await deleteImagesFromLotOffer({
      imageId: imageId,
      endpoint: user.role,
    });
    if (response) {
      return { headers: response.headers, data: response.data };
    }
  });
});

export const lotOfferSlice = createSlice({
  name: "lotOffer",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
    clearClickedMarker: (state) => {
      state.clickedMarker = undefined;
    },
    setPriceType: (state, action: PayloadAction<string>) => {
      // TODO: set price type on model
    },
    setStatus: (state, action: PayloadAction<LotOfferStatus>) => {
      if (state.lotOffer) {
        state.lotOffer.status = action.payload;
        state.changedValues.indexOf("status") === -1 && state.changedValues.push("status");
      }
    },
    setPriceGross: (state, action: PayloadAction<string>) => {
      if (state.lotOffer) {
        state.lotOffer.price_per_solid_cubic_meter = (Number(action.payload) / (100 + Number(state.lotOffer.vat_rate)) * 100).toFixed(2);
        state.changedValues.indexOf("price_per_solid_cubic_meter") === -1 && state.changedValues.push("price_per_solid_cubic_meter");
      }
    },
    setImages: (state, action: PayloadAction<LotImagesType>) => {
      if (state.lotOffer && typeof action.payload == "string") {
        state.lotOffer.images.push(action.payload);
        state.changedValues.indexOf("images") === -1 && state.changedValues.push("images");
      }
    },
    // TODO: Set net price on right property
    setPriceNet: (state, action: PayloadAction<string>) => {
      if (state.lotOffer) {
        state.lotOffer.price_per_solid_cubic_meter = action.payload;
        state.changedValues.indexOf("price_per_solid_cubic_meter") === -1 && state.changedValues.push("price_per_solid_cubic_meter");
      }
    },
    setPublishedAt: (state, action: PayloadAction<string>) => {
      if (state.lotOffer) {
        // console.log("lotOfferSlice, setPublished At", action.payload);
        state.lotOffer.published_at = action.payload;
        state.changedValues.indexOf("published_at") === -1 && state.changedValues.push("published_at");
      }
    },
    setIdsToDelete: (state, action: PayloadAction<string>) => {
      if (state.lotOffer) {
        state.lotOffer.images = state.lotOffer?.images.filter((image) => {
          let fitsFilter = true;
          if (typeof image == "object") {
            fitsFilter = image.id !== action.payload;
          } else {
            fitsFilter = image !== action.payload;
          }
          return fitsFilter;
        });
        state.deletedImageIds.push(action.payload);
      }
    },
    setMarkerClicked: (state, action: PayloadAction<LotOfferLocationInterface>) => {
      state.clickedMarker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLotOffer.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.lotOffer = action.payload.data.data;
      })
      .addCase(patchLotOffer.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
      })
      .addMatcher(isAnyOf(getLotOffer.pending, patchLotOffer.pending), (state) => {
        state.status = RequestStatus.loading;
      })
      .addMatcher(isAnyOf(getLotOffer.rejected, patchLotOffer.rejected), (state) => {
        state.status = RequestStatus.failed;
      });
  },
});

export const { clear, setStatus, setPriceGross, setPriceNet, setPriceType, setPublishedAt, setImages, setIdsToDelete, setMarkerClicked, clearClickedMarker } = lotOfferSlice.actions;

export const selectLotOffer = (
  state: RootState
): LotOfferInterface | undefined => state.lotOffer.lotOffer;

export const selectLotOfferGrossPrice = (
  state: RootState
): string => (
  (Number(state.lotOffer.lotOffer?.price_per_solid_cubic_meter || "0") * (1 + Number(state.lotOffer.lotOffer?.vat_rate || 0) / 100)) || 0).toFixed(2);


export const selectLotOfferWoodPiles = (
  state: RootState
): WoodPileInterface[] => state.lotOffer.lotOffer?.wood_piles || [];

export const selectLotOfferImages = (state: RootState): LotImagesType[] => {
  const lotOffer = state.lotOffer.lotOffer;
  return lotOffer && lotOffer.images ? lotOffer.images : [];
};


export const selectImagesToDeleteLength = (state: RootState): number =>
  state.lotOffer.deletedImageIds?.length;

// export const selectLotOfferWoodTypes = (
//   state: RootState
// ): string[] | undefined =>
//   state.lotOffer.lotOffer?.lot_offers_wood_types.map(
//     (woodType) => woodType.wood_type.name
//   );

export const selectLotOfferWoodTypes = (
  state: RootState
): string[] | undefined => {
  const lotOffer = state.lotOffer.lotOffer;
  if (!lotOffer) {
    return undefined;
  }
  const woodTypes = lotOffer.lot_offers_wood_types;
  if (!woodTypes) {
    return undefined;
  }
  return woodTypes.map((woodType) => woodType.wood_type.name);
};

export const selectLotOfferRequestStatus = (state: RootState): RequestStatus =>
  state.lotOffer?.status;

export const selectClickedMarker = (state: RootState): LotOfferLocationInterface | undefined =>
  state.lotOffer.clickedMarker;

export default lotOfferSlice.reducer;
