import { Typography, InputLabel, Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderDateInput from "components/inputs/HolzfinderDateInput";
import HolzfinderNumberInput from "components/inputs/HolzfinderNumberInput";
import HolzfinderSelect from "components/inputs/HolzfinderSelect";
import { selectLotOffer, selectLotOfferGrossPrice, setPriceGross, setPriceNet, setPublishedAt, setStatus } from "features/lotOffer/lotOfferSlice";
import { LotOfferInterface } from "interfaces/LotOfferInterface";
import { LotOfferStatus } from "interfaces/LotOfferStatus";
import LotOfferGrid from "./LotOfferGrid";
import { useState } from "react";
import { formatNumberInput, validateNumberInput } from "functions/lotPrice";

const LotOfferOptionsInputs = {

  price_type: {
    label: "Angezeigeter Angebotspreis ist",
    default: "Netto",
    options: [
      {
        value: "gross",
        label: "Brutto",
      },
      {
        value: "net",
        label: "Netto",
      },
    ],
  },
  price_gross: {
    label: "Brutto-Preis €/Fm",
  },
  price_per_solid_cubic_meter: {
    label: "Netto-Preis €/Fm",
  },
  activation: {
    label: "Aktivierung",
    default: LotOfferStatus.INACTIVE,
    options: [
      {
        value: LotOfferStatus.ACTIVE,
        label: "Aktiv",
      },
      {
        value: LotOfferStatus.INACTIVE,
        label: "Inaktiv",
      },
      {
        value: LotOfferStatus.PLANNED,
        label: "Aktivierung planen",
      },
    ],
  },
};

export default function LotOfferOptions() {
  const lotOfferData = useAppSelector(selectLotOffer) as LotOfferInterface;
  const grossPrice = useAppSelector(selectLotOfferGrossPrice);
  const dispatch = useAppDispatch();
  const handleActivationChange = (event: any) => {
    dispatch(setStatus(event.target.value));
  };

  const [error, setError] = useState<string | null>(null);
  const handlePriceChangeNet = (value: string) => {
    const isValidPrice = /^\d+((\.|,)\d+)?$/.test(value);
    if (isValidPrice) {
      setError("");
      const netPrice = Number(value);
      dispatch(setPriceNet(netPrice.toFixed(2)));
    } else {
      setError("Bitte geben Sie einen positiven Betrag an.");
      dispatch(setPriceNet(""));
    }
  };

  const handlePriceChangeGross = (value: string) => {
    const isValidPrice = /^\d+((\.|,)\d+)?$/.test(value);
    if (isValidPrice) {
      setError("");
      const grossPrice = Number(value)
      dispatch(setPriceGross(grossPrice.toFixed(2)));
    } else {
      setError("Bitte geben Sie einen positiven Betrag an.");
      dispatch(setPriceGross(""));
    }
  };


  const handlePlannedDate = (value: string) => {
    dispatch(setPublishedAt(value));

  };

  const lotOfferSold = lotOfferData?.status === "sold";

  return (
    <LotOfferGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 1,
          color: "#072D26",
        }}
      >
        OPTIONEN
      </Typography>

      <InputLabel>{LotOfferOptionsInputs.price_per_solid_cubic_meter.label}</InputLabel>
      {error && <Alert severity="error">{error}</Alert>}
      <HolzfinderNumberInput
        unit="Netto Preis €/Fm"
        value={lotOfferData ? Number(Number(lotOfferData.price_per_solid_cubic_meter).toFixed(2)) : 0}
        onChange={handlePriceChangeNet}
        validateInput={validateNumberInput}
        formatInput={formatNumberInput}
        disabled={lotOfferSold}
      />
      <InputLabel>{LotOfferOptionsInputs.price_gross.label}</InputLabel>
      <HolzfinderNumberInput
        unit="Brutto Preis €/Fm"
        value={lotOfferData ? grossPrice : 0} // price_per_solid_cubic_meter * 1.${ vatRate / 100 } eg: 100*1.055
        onChange={handlePriceChangeGross}
        validateInput={validateNumberInput}
        formatInput={formatNumberInput}
        disabled={lotOfferSold}
      />
      <HolzfinderSelect
        id={"activation"}
        label={LotOfferOptionsInputs.activation.label}
        options={LotOfferOptionsInputs.activation.options}
        currentValue={lotOfferData?.status || LotOfferOptionsInputs.activation.default}
        defaultValue={LotOfferOptionsInputs.activation.default}
        onChange={handleActivationChange}
        inputProps={{ "aria-label": "Without label" }}
        disabled={lotOfferSold}
      />
      {lotOfferData?.status === LotOfferStatus.PLANNED ? <HolzfinderDateInput value={lotOfferData.published_at} onChange={handlePlannedDate} disablePast={true} /> : undefined}
    </LotOfferGrid>
  );
}
